import React from "react"
import { navigate } from "gatsby"
import Seo from "../components/common/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import Layout from "../components/common/layout"
import Banner from "../components/common/banner"
import bgImage from "../images/articles/banner.png"

const NotFoundPage = () => {
  const links = [{ title: "Conteúdo", path: "/conteudo" }]
  return (
    <Layout>
      <Seo title="404" />
      <Banner title="Conteúdo" image={bgImage} links={links} />
      <Container className="article single-post half-padding-top padding-bottom">
        <Row className="bg-grey no-gutters pb-3">
          <Col>
            <h2>404: Não encontrado</h2>
          </Col>
          {/* <Col md={12}>
            <div className="pt-3 px-4">
              <div className="d-flex align-items-center article-title">
                <div className="date-square">
                  <div>
                    <span className="day">{format(created_at, "dd")}</span>
                    <span className="month text-uppercase">
                      {format(created_at, "MMM", { locale: pt })}
                    </span>
                  </div>
                </div>
                <div>
                  <h2>{article.title}</h2>
                  <div className="separator" />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="py-3 px-4">
              <div
                dangerouslySetInnerHTML={{ __html: article.content }}
                className="post-content"
              />
            </div>

          </Col> */}
          <div className="text-center">
            <Button onClick={() => navigate("/conteudo")}>Voltar</Button>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
